

























import Vue from 'vue'
import axios from 'axios'
import { getCurrentUser } from '@/lib/user'
// import { formatDate, formatPrice, formatDateLabel } from '../../lib/utils'

export default Vue.extend({
  name: 'Settings',
  data() {
    return {
      queueStatus: null as Record<string, any> | null,
    }
  },
  async created() {
    this.getStatus()
  },
  methods: {
    async getStatus() {
      const currentUser = await getCurrentUser()
      const { data } = await axios.get(
        `${process.env.VUE_APP_PAYMENTS_ENDPOINT}/admin/queues/status`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
          },
        }
      )
      this.queueStatus = data
    },
    async enableQueue() {
      const currentUser = await getCurrentUser()
      await axios.put(
        `${process.env.VUE_APP_PAYMENTS_ENDPOINT}/admin/queues/enable`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
          },
        }
      )
      this.getStatus()
    },
    async disableQueue() {
      const currentUser = await getCurrentUser()
      await axios.put(
        `${process.env.VUE_APP_PAYMENTS_ENDPOINT}/admin/queues/disable`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
          },
        }
      )
      this.getStatus()
    },
  },
})
